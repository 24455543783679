import { Injectable } from '@angular/core';

import { User } from '../../models/user';

import { UserDto } from './dto/user.dto';
import { IMapper } from './mappers';

/** Mapper for User. */
@Injectable({ providedIn: 'root' })
export class UserMapper implements IMapper<UserDto, User> {
	/** @inheritdoc */
	public fromDto(dto: UserDto): User {
		return new User({
			id: dto.id,
			firstName: dto.first_name,
			lastName: dto.last_name,
			email: dto.email,
			phone: dto.phone,
			phoneExt: dto.phone_ext,
			address: dto.address1,
			city: dto.city,
			state: dto.state,
			zipCode: dto.zip_code,
			avatar: dto.avatar,
			isActive: dto.is_active,
		});
	}

	/** @inheritdoc */
	public toDto(data: User): UserDto {
		return {
			id: data.id,
			first_name: data.firstName,
			last_name: data.lastName,
			email: data.email,
			phone: data.phone,
			phone_ext: data.phoneExt,
			address1: data.address,
			city: data.city,
			state: data.state,
			zip_code: data.zipCode,
			avatar: data.avatar,
			is_active: data.isActive,
		};
	}
}
