import { StrictOmit } from '../utils/types/strict-omit';

/** User. */
export class User {
	/** User id. */
	public readonly id: number;

	/** First name. */
	public readonly firstName: string;

	/** Last name. */
	public readonly lastName: string;

	/** Email. */
	public readonly email: string;

	/** Phone. */
	public readonly phone: string;

	/** Phone ext. */
	public readonly phoneExt?: string;

	/** Address. */
	public readonly address: string;

	/** City. */
	public readonly city: string;

	/** State. */
	public readonly state: string;

	/** Zip code. */
	public readonly zipCode: string;

	/** Avatar. */
	public readonly avatar?: string;

	/** Is user active. */
	public readonly isActive: boolean;

	public constructor(data: UserInitArgs) {
		this.id = data.id;
		this.firstName = data.firstName;
		this.lastName = data.lastName;
		this.email = data.email;
		this.phone = data.phone;
		this.phoneExt = data.phoneExt;
		this.address = data.address;
		this.city = data.city;
		this.state = data.state;
		this.zipCode = data.zipCode;
		this.avatar = data.avatar;
		this.isActive = data.isActive;
	}

	/** Get user full name. */
	public getFullName(): string {
		return `${this.firstName} ${this.lastName}`;
	}
}

type UserInitArgs = StrictOmit<User, 'getFullName'>;
